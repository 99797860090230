<template>
  <div class="postdetail">
    <!--  导航  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item v-if="fromName.name!=null" :to="{path:this.fromPath}">{{ fromName.name }}</el-breadcrumb-item>
      <el-breadcrumb-item>帖子详情</el-breadcrumb-item>
    </el-breadcrumb>
    <button class="el-breadcrumb-button" @click="toLastPage">
      <img src="../../../src/assets/images/returnPage.png" alt="返回">返回</button>
    <div class="main">
      <div class="header">{{dataObj.title}}</div>
      <div class="contain">
        <div class="item">发布日期：{{dataObj.createTime | dataFormat}}</div>
        <div class="item">浏览量：{{dataObj.views ? dataObj.views :'1'}}</div>
        <div class="item">点赞数：{{dataObj.likeCount ? dataObj.likeCount :'0'}}</div>
      </div>
      <div class="markdown-body" v-html="dataObj.content" style="text-align:left;width:90%;margin-bottom:50px"><mavon-editor/></div>
      <div @click="speakFeelGood()" class="circle" v-if="!dataObj.likestatus">

        <i class=" comm iconfont icon-dianzan1 "></i>
      </div>
     <!-- <div @click="speakFeelGood" class="circle1" v-if="!dataObj.likestatus">

        <i class=" comm iconfont icon-dianzan "></i>
      </div>-->
      <svg v-else @click="speakFeelGood" id="点赞点击" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="80.061" height="80.06" viewBox="0 0 80.061 80.06">
        <path id="圆角矩形_2381" data-name="圆角矩形 2381" class="cls-1l" d="M960,1661.95a40.051,40.051,0,0,1,40.05,40.05h0a40.049,40.049,0,1,1-80.1,0h0A40.048,40.048,0,0,1,960,1661.95Z" transform="translate(-919.969 -1661.97)"/>
        <image id="矢量智能对象" x="18.031" y="18.03" width="43" height="44" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAsCAYAAAD8WEF4AAACBUlEQVRYhe2Yv2sUQRTHvy9R0CJ2YiEEy4BpAtqYRsQmf4A2QWLrH2B5pLFIr6QTVEhtdyhoCJKAjZLCkEiioigK4SBeQiDxx0fmuJXz3NzuTma5Hci3OHZm3r73ubezM7NPZQk4AzwF9oBvwG1goLSAhxFwl//1qKqwiymwTpMVwPtXwLsDYN9WibMloHkArNOYj89SJjwwLGmoh8klH79lvZ1XMsZHfJyWBXstY/y0j9PgsMA5SRMZZid8fJeR2Zoky7DZ8XEcFLb9lk/lMN3y8R8MFjgu6b6kwRzmX3xihMzsjKS86+eHgHGLCbjRYwNI0/l+gU4A+wVA3SnsWD9ArwK7BbO66BvPe84C1yXVJZ0seOuCb0xrL+KXM+xemtla0gBuSZr1jPlQ0nIBe7fMLZnZugv8Mcejc3NytAP2QcFHf1j9Am66aTCc49+5NfSCXyKDyHHecT/NPkIU0dkB362vD2o62O+RwK7HlNnVI9iS9Dom2FexwL43s81YYJfU3hligG2d1GKBfaFIYL8mJ74YYOeTixhgnyUXR5kNqBUz+/QX1sx+S9quKOyTzkbywVjV7NY7G1WG3Uk2g0RVhn1uZvudHVWGnevu8IUtu7D2WdLj7s4EtlHQmStwbIThSlXNzH6mjgDjQKNHIcLVsy523XMKmAbeAD8CFjTupUJK+gPRzJlstuOc/AAAAABJRU5ErkJggg=="/>
      </svg>

    </div>
    <div class="comment">
      <div class="coo_top">
        <el-input
                type="textarea"
                class="comment_input"
                placeholder="请输入评论内容"
                maxlength="200"
                clearable
                :minlength="200"
                show-word-limit
                v-model="comment">
        </el-input>
        <el-button type="primary" class="setComments" @click="setComments" >评论</el-button>
      </div>
      <div class="comm_content">
        <div class="item" v-for="item in commentList" :key="item.id">
          <div class="left">
            <div class="one">
              <img :src="item.avatarPath" alt="">
            </div>
            <div class="two">
              <div style="font-size: 16px; font-weight: 700;">{{item.username}} <span style="font-size: 12px; color: #797979;margin-left: 26px;font-weight: 500">{{item.createTime | dataFormatDetail}}</span></div>
              <div style="font-size: 12px; color: #797979;margin-top: 12px;">{{item.content}}</div>
            </div>

          </div>
          <div class="right">
            <i :class="[item.likestatus ? 'icon-dianzan': 'icon-dianzan1']" class=" comm iconfont " @click="changeRate(item.id)"></i>
            <span style="font-size: 12px; color: #ed1b24;margin-left: 8px;">{{item.likeCount ? item.likeCount : '0' }}</span>

          </div>
        </div>
        <div class="page_" v-if="total">
          <el-pagination
                  @current-change="currentChange"
                  layout="prev, pager, next"
                  :page-size="pageSize"
                  :current-page="currentPage"
                  :total="total">
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { getDetailSpeak, SpeakDetailGetComments, SpeakDetailSetComments, SpeakDetailRateZan, speakFeelGood } from '@/utils/network'
  export default {
    watch: {
      token(curVal,oldVal){
        this.getData()
        this.getCommentData()
      }
    },
    data() {
      return {
        //详情数据
        dataObj:{},
        fromPath:"",
        fromName: {},
        //评论内容
        comment:'',
        //文章id
        article_id:null,
        //获取评论列表
        commentList:[],
        // 分页
        pageSize:4,
        currentPage:1,
        total:null,
        likecount:'',
        isLikeArticle:false,
        isLikeComment:false,
        //导航数组
        navs: [
          {
            path: '/home',
            name: '首页'
          },
          {
            path: '/search',
            name: '搜索'
          },
          {
            path: '/speak',
            name: '热门帖子'
          }
        ],
        isGood:false //是否点过赞
      }
    },
    beforeRouteEnter(to,from,next) {
      next(vm => {vm.fromPath = from.path
        for(let member of vm.navs) {
          if (vm.fromPath === member.path&&vm.fromPath!=='/home') {
            vm.fromName = member
          }
        }})
    },
    computed:{
      user_id() {
        return this.$store.state.id
      },
      token() {
        return this.$store.state.token
      },

    },
    created() {
      this.article_id = this.$route.params.id
      this.getData()
      this.getCommentData()
    },
    methods: {
      toLastPage(){
        this.$store.dispatch('isLastPage', {
          isLastPage: true
        })
        this.$router.go(-1);
      },
      //帖子点赞
      async speakFeelGood() {
       /* console.log(11)
        console.log(this.token)*/
        if(this.token) {
          const {data: res} = await speakFeelGood({
            articleId: this.article_id,
            likeUid: this.user_id
          })
          // console.log(res)
          if(res.code !== 200) return this.$message.error('网络繁忙')
          this.dataObj = res.data
          //this.getData()
          /*if(res.data.status === 1) {
            this.dataObj.likeCount++
            this.isLikeArticle = true
            this.dataObj.likestatus = 1
          }else {
            this.isLikeArticle = false
            this.dataObj.likeCount--
            this.dataObj.likestatus = 0
          }*/

        } else {
          this.$store.dispatch('changeDialogShow', {
            loginDialogVisible: true
          })
        }

      },
      async changeRate(commID) {
        if(this.token) {
          const obj = {}
          obj.likeUid = this.user_id
          obj.commentId = commID
          const {data: res} = await SpeakDetailRateZan(obj)
          // console.log(res)
          if(res.code !== 200) return
          if(res.data.status === 1) {
            this.isLikeComment = true
          } else {
            this.isLikeComment = false
          }
          this.getCommentData()

        }else {
          this.$store.dispatch('changeDialogShow', {
            loginDialogVisible: true
          })
        }
      },
      currentChange(index) {
        this.currentPage = index
        this.getCommentData()

      },
      //发表评论
      async setComments() {
        if(this.token) {
          if(this.comment.trim() !== '') {
            if(this.comment.length >= 10) {
              const { data: res } = await SpeakDetailSetComments({
                commenterUid:this.user_id,
                articleId: this.article_id,
                content:this.comment,
                parentId:"0"
              })
              if(res.code === 403 && res.message.indexOf("failed")) {
                this.$store.dispatch('changeDialogShow', {
                  loginDialogVisible: true
                })
              } else if(res.code === 200) {
                this.$message.success('评论成功，待审核')
                this.comment = ''
                this.getCommentData()
              }else {
                this.$message.error(`${res.message}`)
              }
            } else {
              this.$message.error('评论内容需10个字以上')
            }

          }else {
            this.$message.error('请填写评论内容')
            this.comment = ''
          }
        }else {
          this.$store.dispatch('changeDialogShow', {
            loginDialogVisible: true
          })
        }
      },
      async getCommentData() {
        const {data: res} = await SpeakDetailGetComments({
          articleId: this.article_id,
          page:this.currentPage,
          per_page:this.pageSize
        })
        if (res.code !== 200) return  this.$message.error('请求失败')
        this.total = res.data.total
        this.commentList = res.data.vodata

      },
      async getData() {
        const { data: res } = await getDetailSpeak({
          article_id:this.article_id,
        })
        if (res.code !== 200) return  this.$message.error('请求失败')
        this.dataObj = res.data
        // this.$message.success('获取成功')
      }
    },
    components: {

    }
  }
</script>

<style lang="less" scoped>
  .postdetail {
    //background-color: #fff;
    width: 80%;
    margin: 20px auto;

    /* 面包屑导航 */
    .el-breadcrumb {
      margin-left: 10px;
    @include ptrbl(16px,16px,16px,16px);
      box-sizing: border-box;
      width: 50%;
    }

    .el-breadcrumb-button{
      img{
        height: 14px;
        margin-right: 5px;
      }
      background-color: rgba(0, 0, 0, 0);
      margin-right: 10px;
      position:relative;
      margin-top: -17px;
      float:right;
      font-weight: 700;
      text-decoration: none;
      border: none;
      transition: color .2s cubic-bezier(.645,.045,.355,1);
      color: #303133;
      vertical-align: middle;
      &:hover {
        color: red;
      }
    }

    .main {
      display: flex;
      background-color: #fff;
      padding: 30px;
      flex-direction: column;
      border-radius: 8px;
      align-items: center;
      margin: 20px 0;
      .header {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 10px;
      }
      .contain {
        width: 30%;
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;
        line-height:40px ;
        height: 40px;
        font-size: 12px;
        color: #797979;
        .logo {
          vertical-align: middle;
          height: 39px;
          border-radius: 50%;
          text-align: center;
          margin-right: 8px;
        }
      }
      /deep/ .markdown-body {
        margin-bottom: 20px;
        white-space: pre;
        width: 80%;
        flex-wrap: wrap;
        p{
          word-wrap: break-word;
          word-break: break-all;
          white-space: pre-wrap;
          line-height: 30px;
        }
        table{
          display: table;
          margin: 0 auto;
        }
        img{
          max-width: 800px;
          margin:0 auto;
          display: block;
        }
        .hljs-center{
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
        }

      }
    .circle {
        position: relative;
        width: 86px;
        height: 86px;
        border: 2px solid #ed1b24;
        border-radius: 50%;
        &:hover{
          color: red;
          background-color: #ffd6d6;
        //  opacity: .3;
        }
        .comm {
          font-size: 40px;
          position: absolute;
          top:50%;
          left: 50%;
          color: #ed1b24 ;
          transform: translate(-50%, -50%);
        }
      }
      .circle1 {
        position: relative;
        width: 86px;
        height: 86px;
        background-color: #ed1b24;
        border: 2px solid #ed1b24;
        border-radius: 50%;
        &:hover{
          color: red;
          background-color: #ffd6d6;
          //  opacity: .3;
        }
        .comm {
          z-index: 999;
          font-size: 40px;
          position: absolute;
          top:50%;
          left: 50%;
          color: #fff;
          transform: translate(-50%, -50%);
        }
      }
      .cls-1 {
        fill: none;
        stroke: #ed1b24;
        stroke-width: 1px;
        fill-rule: evenodd;
      }
      .cls-1l {
        fill: #ed1b24;
        fill-rule: evenodd;
      }
    }
    .comment {
      margin: 30px 0;
      background-color: #fff;

      border-radius: 10px;
      padding: 20px;

      .coo_top {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      .comm_content {
        border-bottom: 1px solid #ccc;
        .item{
          display: flex;
          height: 100px;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid #ccc;
          .left {
            display: flex;
            align-items: center;
            .one {
              margin-right: 12px;
              img {
                width: 51px;
                height: 51px;
                border-radius: 50%;
              }
            }
          }
          .right {
            .comm {
              cursor: pointer;
              color: #ed1b24;

            }
          }
        }

      }
    }
  }

</style>
